import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { AnswerSchema, FormStatusEnum } from "./form";

export const InternalFormItemType = z.object({
  question: z.string(),
  questionType: z.string(),
  answer: AnswerSchema.optional(),
  options: z.array(z.any()).optional(),
  min: z.number().optional(),
  max: z.number().optional(),
});

export const InternalFormSchema = BaseSchema.extend({
  status: FormStatusEnum,
  title: z.string().optional(),
  items: z.array(InternalFormItemType),
  submittedAt: z.date().optional(),
  description: z.string().optional(),
  memberId: z.string(),
  clientId: z.string().optional(),
  appointmentId: z.string().optional(),
  templateId: z.string(),
});

export type InternalFormType = z.infer<typeof InternalFormSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "internal_forms",
  deleteMode: "soft",
};
