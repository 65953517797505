import { useAuth } from "@contexts/auth";
import {
  useGetFirestoreCollectionData,
  useGetFirestoreDocumentData,
} from "@contexts/data";

import useAccessType from "./use-access-type";

export const useInternalForm = (formId: string) => {
  const { oid, aid } = useAuth();
  const { permissions } = useAccessType();

  const { data, error } = useGetFirestoreDocumentData(
    oid ? `users/${oid}/internal_forms/${formId}` : undefined
  );

  const form =
    permissions?.canViewAllContacts || data?.memberId === aid
      ? data
      : undefined;

  return {
    form,
    error,
  };
};

export const useInternalFormsForContact = (contactId: string) => {
  const { oid, aid } = useAuth();
  const { permissions } = useAccessType();

  const { data, error } = useGetFirestoreCollectionData({
    collectionName:
      oid && contactId ? `users/${oid}/internal_forms` : undefined,
    queryList: [["clientId", "==", contactId]],
  });

  const filteredForms = permissions?.canViewAllContacts
    ? data
    : data?.filter(({ memberId }) => memberId === aid);

  return {
    forms: filteredForms,
    error,
  };
};

export const useInternalFormsForAppointment = (apptId: string | undefined) => {
  const { oid, aid } = useAuth();
  const { permissions } = useAccessType();

  const { data, error } = useGetFirestoreCollectionData({
    collectionName: oid && apptId ? `users/${oid}/internal_forms` : undefined,
    queryList: [["appointmentId", "==", apptId]],
  });

  const filteredForms = permissions?.canViewAllContacts
    ? data
    : data?.filter(({ memberId }) => memberId === aid);

  return {
    forms: filteredForms,
    error,
  };
};
