import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { SmartActionTemplateSchema } from "./smart-action-template";

export const FormStatusEnum = z.enum([
  "draft",
  "scheduled",
  "shared",
  "viewed",
  "pending",
  "submitted",
  "active",
  "deleted",
]);

const SignatureAnswerSchema = z.object({
  signatureId: z.string(),
  signatureRequestId: z.string(),
});

export type SignatureAnswerType = z.infer<typeof SignatureAnswerSchema>;

const MultipleChoiceAnswerSchema = z.object({
  value: z.string(),
});

const FileSchema = z.object({
  url: z.string(),
  fileName: z.string(),
  size: z.number(),
  contentType: z.string(),
  fullPath: z.string().optional(),
  bucket: z.string().optional(),
  extension: z.string().optional(),
  itemId: z.string().optional(),
});

export type FileType = z.infer<typeof FileSchema>;

export const AnswerSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  FileSchema.nullable(),
  MultipleChoiceAnswerSchema.array(),
  SignatureAnswerSchema,
]);

export const FormSchema = BaseSchema.extend({
  status: FormStatusEnum,
  title: z.string().optional(),
  items: z.array(
    z.object({
      question: z.string(),
      questionType: z.string(),
      answer: AnswerSchema.optional(),
      min: z.number().optional(),
      max: z.number().optional(),
    })
  ),
  coachUserId: z.string(),
  submittedAt: z.date().optional(),
  description: z.string().optional(),
  contactId: z.string(),
  smartActions: SmartActionTemplateSchema.array().optional(),
  scheduledSmartActions: z.array(z.string()).optional(),
  appointmentId: z.string().optional(),
});

export type FormType = z.infer<typeof FormSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "forms",
  deleteMode: "none",
};
