import { FC } from "react";
import Link from "next/link";

import { useContact } from "@hooks/data/clients";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";

import ClientAvatar from "@components/Client/ClientAvatar";
import InfoBoxDetailsPage from "@components/DetailsPage/InfoBoxDetailsPage";
import ClientIcon from "@components/Icons/ClientIcon";

const ClientLink = ({ client, showPrimaryIndicator = false }) => {
  return (
    <Link
      href={`/contacts/${client?.id}`}
      className="inline-flex items-center w-max border-b border-black-ink font-medium leading-4"
    >
      {displayNameFromContact(client)}
      {showPrimaryIndicator && <>*</>}
    </Link>
  );
};

const ClientLinkById = ({ clientId }) => {
  const { contact } = useContact(clientId);
  return (
    <Link
      href={`/contacts/${contact?.id}`}
      className="inline-flex items-center w-max border-b border-black-ink font-medium leading-4"
    >
      {displayNameFromContact(contact)}
    </Link>
  );
};

interface InfoBoxDetailsPlaceholderProps {
  title: string;
}
const InfoBoxDetailsPlaceholder: FC<InfoBoxDetailsPlaceholderProps> = ({
  title,
}) => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse";
  const Icon = () => <div className={`${style} w-10 h-10 rounded-full`} />;
  return (
    <InfoBoxDetailsPage title={title} icon={<Icon />} iconClassName="w-10 h-10">
      <div className="w-20 h-2 my-2 bg-grey-900 dark:bg-grey-250 rounded-full" />
    </InfoBoxDetailsPage>
  );
};

interface ClientInfoBoxDetailsPageProps {
  client?: ClientType;
  clientIds?: string[];
  isLoading?: boolean;
}
const ClientInfoBoxDetailsPage: FC<ClientInfoBoxDetailsPageProps> = ({
  client,
  clientIds = [],
  isLoading = false,
}) => {
  const otherClientIds = clientIds?.filter((id) => id !== client?.id);
  const title = otherClientIds.length > 0 ? "Clients" : "Client";

  if (isLoading) {
    return <InfoBoxDetailsPlaceholder title={title} />;
  }

  return (
    <InfoBoxDetailsPage
      title={title}
      iconClassName="bg-grey-950"
      icon={
        client?.id && otherClientIds.length == 0 ? (
          <ClientAvatar client={client} />
        ) : (
          <ClientIcon />
        )
      }
    >
      {client?.id ? (
        <>
          <ClientLink
            client={client}
            showPrimaryIndicator={otherClientIds.length > 0}
          />
          {otherClientIds?.map((id) => {
            return (
              <>
                , <ClientLinkById clientId={id} />
              </>
            );
          })}
        </>
      ) : (
        <div className="font-medium leading-4">No client</div>
      )}
    </InfoBoxDetailsPage>
  );
};

export default ClientInfoBoxDetailsPage;
