import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

import {
  InternalFormItemType,
  InternalFormSchema,
} from "@lib/data/schemas/internal-form";

const tags = ["internal-forms"];

const InternalFormCommonPath = z.object({
  userId: z.string(),
});

const PostInternalFormSchema = z.object({
  title: z.string(),
  items: InternalFormItemType.array(),
  memberId: z.string(),
  templateId: z.string(),
  clientId: z.string().optional(),
  appointmentId: z.string().optional(),
});

export const postInternalForm = getSchemaDefinition(
  "/api/v1/users/{userId}/internal-forms",
  "post",
  {
    path: InternalFormCommonPath,
    body: PostInternalFormSchema,
  },
  {
    description: "Creates an internal form.",
    tags,
  },
  InternalFormSchema
);

export const updateInternalForm = getSchemaDefinition(
  "/api/v1/users/{userId}/internal-forms/{formId}",
  "put",
  {
    path: InternalFormCommonPath.extend({ formId: z.string() }),
    body: PostInternalFormSchema.partial(),
  },
  {
    description: "Updates an internal form.",
    tags,
  },
  z.object({ ok: z.boolean() })
);

export const deleteInternalForm = getSchemaDefinition(
  "/api/v1/users/{userId}/internal-forms/{formId}",
  "delete",
  {
    path: InternalFormCommonPath.extend({ formId: z.string() }),
  },
  {
    description: "Deletes an internal form.",
    tags,
  },
  z.object({ ok: z.boolean() })
);
