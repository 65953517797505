import React from "react";

const SeparatorDetailsPage = () => {
  return (
    <div className="block">
      <div className="py-5">
        <div className="border-t border-gray-200"></div>
      </div>
    </div>
  );
};

export default SeparatorDetailsPage;
