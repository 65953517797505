import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import {
  postInternalForm,
  updateInternalForm,
} from "api-services/definitions/internal-forms";
import { useApi } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";
import { useCurrencies } from "@hooks/data/currencies";
import useSnackbar from "@hooks/use-snackbar";
import { FormTemplateType } from "@lib/data/schemas/form-template";
import { InternalFormType } from "@lib/data/schemas/internal-form";

import { IntakeFormFields } from "@components/IntakeForm/IntakeFormFields";

import BigModal from "../BigModal";

interface InternalFormModalProps {
  toggleShow: (show: boolean) => void;
  show: boolean;
  template: FormTemplateType;
  form?: InternalFormType;
  clientId?: string;
  appointmentId?: string;
  setSuggestedFormId?: (id: string) => void;
}

type InternalFormResponseType = { answers: any[] };

const InternalFormModal: FC<InternalFormModalProps> = ({
  toggleShow,
  show,
  template,
  clientId,
  appointmentId,
  form,
  setSuggestedFormId,
}) => {
  const { oid, aid } = useAuth();
  const snackbar = useSnackbar();
  const { apiCall: createInternalForm } = useApi(postInternalForm);
  const { apiCall: internalFormUpdate } = useApi(updateInternalForm);
  const {
    handleSubmit,
    formState: { isDirty, errors },
    setValue,
    register,
    control,
  } = useForm<InternalFormResponseType>({
    ...(form && {
      defaultValues: { answers: form.items.map((item) => item.answer) },
    }),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currencies = [] } = useCurrencies();

  const title = form?.title || template.title;

  const onSubmitForm = async (data: InternalFormResponseType) => {
    setIsLoading(true);

    try {
      if (form) {
        const items = form.items.map((item, i) => ({
          ...item,
          answer: data.answers[i],
        }));
        const payload = { items };
        await internalFormUpdate(
          { userId: oid!, formId: form.id },
          payload,
          {}
        );
      } else if (template) {
        const items = template.items.map((item, i) => ({
          ...item,
          answer: data.answers[i],
        }));

        const payload = {
          items,
          memberId: aid!,
          title: template.title,
          description: template.description,
          templateId: template.id,
          ...(clientId && { clientId }),
          ...(appointmentId && { appointmentId }),
        };

        await createInternalForm({ userId: oid! }, payload, {});
      }
      if (setSuggestedFormId) setSuggestedFormId(template.id);
      snackbar.showMessage("Form submitted successfully!");
    } catch (e) {
      snackbar.showWarning("Something went wrong!");
    } finally {
      setIsLoading(false);
      toggleShow(false);
    }
  };

  return (
    <BigModal
      show={show}
      toggleShow={toggleShow}
      onActionText="Submit"
      onActionLoading={isLoading}
      onAction={handleSubmit(onSubmitForm)}
      title={title}
      description="Private form"
      className="overflow-hidden w-full"
      contentClassName="!overflow-auto"
      isDirty={isDirty}
    >
      <IntakeFormFields
        form={form ?? template}
        setValue={setValue}
        register={register}
        control={control}
        errors={errors}
        coachId={oid}
        currencies={currencies}
        internal
      />
    </BigModal>
  );
};

export default InternalFormModal;
