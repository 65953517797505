import React, { FC, ReactNode } from "react";
import classNames from "classnames";

interface InfoSectionDetailsPageProps {
  className?: string;
  children: ReactNode;
}

const InfoSectionDetailsPage: FC<InfoSectionDetailsPageProps> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames("bg-white py-5", className)}>
      <div className="md:grid md:grid-cols-2 md:gap-4">{children}</div>
    </div>
  );
};

export default InfoSectionDetailsPage;
