import React from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

import { getChoiceFormItem } from "./utils";

function SingleChoiceField({ value, onChange, readonly, item: formItem }) {
  const item = getChoiceFormItem(formItem);

  return (
    <RadioGroup disabled={readonly} value={value} onChange={onChange}>
      <RadioGroup.Label className="sr-only">{item.question}</RadioGroup.Label>
      <div className="bg-background text-foreground space-y-2">
        {item.options?.map((option, optionIdx) => (
          <RadioGroup.Option
            key={optionIdx}
            value={option.value}
            className={() =>
              classNames(
                optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                optionIdx === item.options.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                "relative flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ checked }) => (
              <>
                <span
                  className={classNames(
                    checked &&
                      "bg-background focus:outline-none border-accent/100 flex-none",
                    "bg-background text-foreground border-foreground/50 flex-none ",
                    "h-5 w-5 mt-0.5 cursor-pointer rounded-full border-2 flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span
                    className={checked && "rounded-full bg-accent w-2 h-2"}
                  />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-foreground" : "text-foreground/80",
                      "block text-base"
                    )}
                  >
                    {option?.label ? option.label : option.value}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default SingleChoiceField;
